.shadowCustom1 {
    text-shadow: 3px 0px 7px rgba(134,123,111,0.8), 
	  -3px 0px 7px rgba(134,123,111,0.8), 
	  0px 4px 7px rgba(134,123,111,0.8);
}
.shadowCustom2 {
    text-shadow: 3px 0px 7px rgba(135,120,95,0.8), 
	  -3px 0px 7px rgba(135,120,95,0.8), 
	  0px 4px 7px rgba(135,120,95,0.8);
}
.shadowCustom3 {
    text-shadow: 3px 0px 7px rgba(176,164,161, 0.8), 
	  -3px 0px 7px rgba(176,164,161,0.8), 
	  0px 4px 7px rgba(176,164,161,0.8);
}
// rgb(97,57,20)
.videoContainer {
    max-width: 100%;
    max-height: 100%;
    overflow: hidden;
    video {
    width: 100vw;
    height: 100vh;
     /* Videoyu ekrana tam sığacak şekilde ölçekler */
    object-fit: cover;
  }
  }
  @media (max-width: 767px) {
    .videoContainer video {
      
      width:revert-layer; /* Ekran genişliği kadar */
      width:-webkit-fit-available;
      height: auto; /* Orantılı yükseklik */
    }
  }
  