.card-form-horizontal{
    .card-body{
        padding-left: 15px;
        padding-right: 15px;
    }
    .form-group{
        padding-bottom: 0px;
        margin: 3px 0 0 0;

        .form-control{
            margin-bottom: 0;
        }
    }
    .btn{
        margin: 0;
    }
    .input-group,
    .form-group{
        margin: 0;
    }
}
